<template>
  <b-card>

    <b-row>

      <!-- ContentItem Info: Left col -->
      <b-col
        cols="21"
        xl="5"
        class="d-flex justify-content-between flex-column"
      >
        <!-- ContentItem Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="contentItemData.icon_url"
            :text="avatarText(contentItemData.title)"
            size="100px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ contentItemData.title }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: $props.contentType+'-edit', params: { id: contentItemData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                id="deleteTooltip"
                href="#"
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
              <b-tooltip
                ref="deleteTooltip"
                target="deleteTooltip"
                title="Tooltip title"
                triggers="click"
              >
                <div>Are you sure?</div>

                <b-link
                  class="font-weight-bolder"
                  @click="closeDeleteTooltip"
                >
                  Cancel
                </b-link> - <b-link
                  class="text-danger font-weight-bolder"
                  @click="deleteThis"
                >
                  Delete
                </b-link>
              </b-tooltip>
            </div>
          </div>
        </div>
        <table class="mt-3 w-100">
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Last updated</span>
            </th>
            <td class="pb-1">
              {{ formatDate(contentItemData.updated_at) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="EyeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Published status</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(contentItemData.published) ? 'success' : 'light-secondary'">
                {{ (contentItemData.published) ? 'Published' : 'Draft' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Featured</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(contentItemData.featured) ? 'success' : 'light-secondary'">
                {{ (contentItemData.featured) ? 'Featured' : 'Not featured' }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="$themeConfig.app.allowShowInFeed.includes(contentItemData.content_type)">
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="FileTextIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Show in news feed</span>
            </th>
            <td class="pb-1 text-capitalize">
              <b-badge :variant="(contentItemData.news) ? 'success' : 'light-secondary'">
                {{ (contentItemData.news) ? 'In news feed' : 'Not in feed' }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Published date</span>
            </th>
            <td class="pb-1">
              {{ formatDate(contentItemData.published_at) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-1 text-nowrap align-top">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Owner</span>
            </th>
            <td class="pb-1">
              <b-link
                :to="{ name: 'users-view', params: { id: contentItemData.owner.id } }"
              >
                {{ contentItemData.owner.name }}
              </b-link>
            </td>
          </tr>
        </table>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="7"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="LinkIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Slug</span>
            </th>
            <td class="align-top">
              <b-link target="_blank" :href="$themeConfig.app.membersUrl +'/'+ $themeConfig.membersApp.routes[$props.contentType] +'/'+ contentItemData.slug">{{ contentItemData.slug }}</b-link>
            </td>
          </tr>
          <tr>
            <th class="pr-1 pb-2 text-nowrap align-top">
              <feather-icon
                icon="FolderIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Category</span>
            </th>
            <td v-if="contentItemData.category" class="align-top">
              <b-badge variant="warning">
                {{ contentItemData.category }}
              </b-badge>
            </td>
            <td v-else class="align-top">
              none
            </td>
          </tr>
          <tr v-if="contentItemData.content_type === 'App\\Models\\Event'">
            <th class="pr-1 pb-2 text-nowrap align-top">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Event type</span>
            </th>
            <td class="align-top">
              <b-badge variant="dark">
                {{ contentItemData.content.online_event? 'Online' : 'In person' }}
              </b-badge>
            </td>
          </tr>
          <tr v-if="contentItemData.content_type !== 'App\\Models\\Event'">
            <th class="pr-1 pb-2 text-nowrap align-top">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Linked to event</span>
            </th>
            <td v-if="contentItemData.parent_event" class="align-top">
              <b-link :to="{ name: 'events-view', params: { id: contentItemData.parent_event.id } }">{{ contentItemData.parent_event.title }}</b-link>
            </td>
            <td v-else class="align-top">
              none
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="TagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Topics</span>
            </th>
            <td class="align-top">
              <ul
                v-if="contentItemData.topics"
                class="list-unstyled list-inline"
              >
                <li
                  v-for="topic in contentItemData.topics"
                  :key="topic.id"
                  class="mr-1"
                >
                  <b-badge variant="dark">
                    {{ topic.name }}
                  </b-badge>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Organisations</span>
            </th>
            <td class="align-top">
              <ul
                v-if="contentItemData.organisations"
                class="list-unstyled list-inline"
              >
                <li
                  v-for="org in contentItemData.organisations"
                  :key="org.id"
                  class="mr-1"
                >
                  <b-badge variant="primary">
                    {{ org.name }}
                  </b-badge>
                </li>
              </ul>
            </td>
          </tr>
          <tr class="mt-2">
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="BarChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Total Views</span>
            </th>
            <td class="align-top">
              {{ contentItemData.content.all_analytics_count }}
            </td>
          </tr>
          <tr>
            <th class="pb-2 pr-1 text-nowrap align-top">
              <feather-icon
                icon="PieChartIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Views (Previous 28 days)</span>
            </th>
            <td class="align-top">
              {{ contentItemData.content.last_28_days_analytics_count }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BButton, BAvatar, BRow, BCol, BLink, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { $themeConfig } from '@/../themeConfig'
import useContentItemsList from '../content-items-list/useContentItemsList'
import formatDateMixin from '../../../@core/mixins/ui/date'

export default {
  components: {
    BBadge, BCard, BButton, BRow, BCol, BAvatar, BLink, BTooltip,
  },
  mixins: [formatDateMixin],
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeDeleteTooltip() {
      this.$refs.deleteTooltip.$emit('close')
    },
  },
  setup() {
    // Use toast
    const toast = useToast()

    const { resolveContentItemRoleVariant } = useContentItemsList()

    const deleteThis = () => {
      const contentType = router.currentRoute.path.split('/')[1]
      const contentId = router.currentRoute.params.id

      store.dispatch('app/deleteContentItem', { contentType, id: contentId })
        .then(() => {
          // Redirect to content items list
          router.push({ name: contentType })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating new user.',
              text: `ERROR: ${error.response.data.message}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      avatarText,
      deleteThis,
      resolveContentItemRoleVariant,
      $themeConfig,
    }
  },
}
</script>

<style>

</style>
