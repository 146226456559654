<template>
  <b-card title="Stats">
    <h4 class="mb-5">
      Statistics about this content item will appear here...
    </h4>
    <div
      v-if="contentItemData.content.attendees && contentItemData.content.attendees.length > 0"
      class="mb-3"
    >
      <h4 class="mb-2">
        Attendees
      </h4>
      <ul class="list-unstyled">
        <li
          v-for="attendee in contentItemData.content.attendees"
          :key="attendee.id"
          class="list-unstyled"
        >
          <b-media vertical-align="center" class="pb-1 mb-1 border-bottom">
            <template #aside>
              <b-avatar
                  :src="attendee.photo_url"
                  :to="{ name: 'users-view', params: { id: attendee.id } }"
                  size="32x"
              />
            </template>
            <b-link
              :to="{ name: 'users-view', params: { id: attendee.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ attendee.name }}
            </b-link>
          </b-media>
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BLink, BMedia,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BMedia,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
