<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="contentItemData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching {{ contentTypeSingular }} data
      </h4>
      <div class="alert-body">
        No {{ contentTypeSingular }} found with this {{ contentTypeSingular }} id. Check
        <b-link
          class="alert-link"
          :to="{ name: contentType}"
        >
          {{ contentName }} List
        </b-link>
        for other {{ contentType }}.
      </div>
    </b-alert>

    <template v-if="contentItemData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <content-items-view-info-card
            :content-item-data="contentItemData"
            :content-type="$props.contentType"
          />
        </b-col>
        <b-col
          cols="12"
        >

          <content-items-view-stats-card
            :content-item-data="contentItemData"
            :content-type="$props.contentType"
          />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import ContentItemsViewInfoCard from './ContentItemsViewInfoCard.vue'
import ContentItemsViewStatsCard from './ContentItemsViewStatsCard.vue'
import ContentItemsViewPermissionsCard from './ContentItemsViewPermissionsCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ContentItemsViewInfoCard,
    ContentItemsViewStatsCard,
    ContentItemsViewPermissionsCard,

  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const contentItemData = ref(null)
    store.dispatch('app/getContentItem', {
      contentType: props.contentType,
      id: router.currentRoute.params.id,
    })
      .then(response => {
        contentItemData.value = response.data.results[0]
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 404) {
            contentItemData.value = undefined
          }
        } else {
          console.error(error)
        }
      })

    return {
      contentItemData,
    }
  },
}
</script>

<style>

</style>
